import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const Sidebar = () => {
  const { logout } = useAuth0();

  return (
    <div className="w-32 bg-white shadow-md flex flex-col h-full">
      <div className="p-4">
      <h1 className="text-2xl font-bold text-blue-600 border-b-2 border-blue-300 pb-2 drop-shadow-sm">Asterio</h1>
      </div>
      <nav className="mt-6 flex-grow">
        <Link
          to="/"
          className="block py-2 px-4 text-gray-700 hover:bg-gray-200"
        >
          Chat
        </Link>
        <Link
          to="/history"
          className="block py-2 px-4 text-gray-700 hover:bg-gray-200"
        >
          History
        </Link>
        <Link
          to="/documents"
          className="block py-2 px-4 text-gray-700 hover:bg-gray-200"
        >
          Documents
        </Link>
        <Link
          to="/profile"
          className="block py-2 px-4 text-gray-700 hover:bg-gray-200"
        >
          Profile
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;
