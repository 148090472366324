import React, { useState, useCallback, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import { useAuth0 } from "@auth0/auth0-react";
import { useChat } from "../ChatContext";
import axios from "axios";
import { tips } from './tips'; // Import tips from the external file
import { useNavigate } from 'react-router-dom';
import '../styles/typing-indicator.css';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://asterio.io';


const ChatInterface = () => {
  const { getAccessTokenSilently, loginWithRedirect, isAuthenticated, isLoading: authLoading } = useAuth0();
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { chatMessages, setChatMessages } = useChat();
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [showTip, setShowTip] = useState(false);
  const [currentTip, setCurrentTip] = useState("");
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const quickTipButtonRef = useRef(null);
  const [suggestedQuestions, setSuggestedQuestions] = useState([]);
  const inputRef = useRef(null);
  const formRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);

  
  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/get-started');
  };

  const handleResourcesClick = () => {
    navigate('/resources');
  };

  const fetchWithToken = useCallback(
    async (url, options = {}) => {
      try {
        const token = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: "openid profile email",
        });
        console.log("Token:", token);

        const response = await fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Response status:", response.status);

        if (response.status === 401) {
          console.log("Unauthorized, attempting to refresh token...");
          await getAccessTokenSilently({ ignoreCache: true });
          return fetchWithToken(url, options);
        }

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(
            `Network response was not ok: ${response.status}, ${errorText}`,
          );
        }

        return response;
      } catch (error) {
        console.error("Error in fetchWithToken:", error);
        if (error.error === "login_required") {
          await loginWithRedirect();
        }
        throw error;
      }
    },
    [getAccessTokenSilently, loginWithRedirect],
  );

  const clearChat = () => {
    setChatMessages([]); // This clears the chat messages
    // Optionally, you might want to clear the selected document as well
    setSelectedDocument(null);
  };

  const generateRelatedQuestions = async (response) => {
    try {
      const result = await fetchWithToken(`${API_BASE_URL}/api/generate-questions`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          context: response,
        }),
      });
  
      const data = await result.json();
      setSuggestedQuestions(data.questions.map(q => q.trim()));
    } catch (error) {
      console.error("Error generating related questions:", error);
      setSuggestedQuestions([]);
    }
  };
  
  const handleSuggestedQuestionClick = useCallback((question) => {
    if (inputRef.current) {
      inputRef.current.value = question;
    }
    setInput(question);
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      }
    }, 0);
  }, []);

  const saveMessageToHistory = async (message, sender) => {
    try {
      await fetchWithToken(`${API_BASE_URL}/api/chat-history`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ message, sender }),
      });
    } catch (error) {
      console.error("Error saving message to history:", error);
    }
  };

  useEffect(() => {
    const fetchDocuments = async () => {
      try {
        const response = await fetchWithToken(
          `${API_BASE_URL}/api/documents`,
        );
        const data = await response.json();
        setDocuments(data);
      } catch (error) {
        console.error("Error fetching documents:", error);
      }
    };

    fetchDocuments();
  }, [fetchWithToken]);

  const showRandomTip = () => {
    console.log('showRandomTip function called');
    if (tips.length > 0) {
      console.log('Tips available:', tips.length);
      const randomTip = tips[Math.floor(Math.random() * tips.length)];
      setCurrentTip(randomTip);
      console.log('Random tip selected:', randomTip);
      
      if (quickTipButtonRef.current) {
        console.log('Button ref exists');
        const rect = quickTipButtonRef.current.getBoundingClientRect();
        console.log('Button rect:', rect);
        const windowWidth = window.innerWidth;
        console.log('Window width:', windowWidth);
        
        // Calculate a position that's to the right of the button
        let leftPosition = rect.right + window.scrollX + 10; // 10px to the right of the button
        
        // Ensure the popup doesn't go off the right edge of the screen
        const popupWidth = 150; // Adjust this to match your popup's width
        if (leftPosition + popupWidth > windowWidth) {
          leftPosition = windowWidth - popupWidth - 0; // 10px from the right edge of the screen
        }
  
        const newPosition = {
          top: rect.bottom + window.scrollY + -100, // 10px below the button
          left: leftPosition,
        };
  
        console.log('New popup position:', newPosition);
    
        setPopupPosition(newPosition);
      } else {
        console.error('Button ref is null');
      }
      
      setShowTip(true);
    } else {
      console.error('No tips available');
    }
  };

  const handleTellMeMore = () => {
    // Define the message you want to send when "Tell me more" is clicked.
    // You can customize this message as needed.
    const message = `Tell me more about: ${currentTip}`;

    setInput(message);       // Set the input state to the predefined message
    setShowTip(false);      // Close the tip popup

    // Use a timeout to ensure that `setInput` has updated the state before submitting
    setTimeout(() => {
      if (formRef.current) {
        formRef.current.dispatchEvent(
          new Event('submit', { cancelable: true, bubbles: true })
        );
      }
    }, 0);
  };



  const handleDocumentChange = (newDocumentId) => {
    setSelectedDocument(newDocumentId);
    if (newDocumentId) {
      const newDoc = documents.find(
        (doc) => doc.id === parseInt(newDocumentId),
      );
      if (newDoc) {
        setChatMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "user",
            content: `I've selected the document "${newDoc.filename}". Please consider this document's content in your future responses.`,
            hidden: true,
          },
          {
            role: "assistant",
            content: `I see you have selected "${newDoc.filename}". I will take this into account when replying.`,
          },
        ]);
      }
    } else {
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "user",
          content: `I've deselected all documents. Please provide general responses without referring to any specific document.`,
          hidden: true,
        },
        {
          role: "assistant",
          content: `Document has been deselected. I will no longer consider it in my reply.`,
        },
      ]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    setSuggestedQuestions([]); // Clear suggested questions

    const userMessage = { role: "user", content: input };
    setChatMessages((prevMessages) => [...prevMessages, userMessage]);
    setInput("");
    setIsLoading(true);

    try {
      await saveMessageToHistory(input, "user");

      const conversationHistory = chatMessages.map((msg) => ({
        role: msg.role === "assistant" ? "assistant" : "user",
        content: msg.content,
      }));

      conversationHistory.push({ role: "user", content: input });

      const response = await fetchWithToken(`${API_BASE_URL}/api/chat`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          messages: conversationHistory,
          documentId: selectedDocument,
        }),
      });

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = { role: "assistant", content: "" };

      setChatMessages((prevMessages) => [...prevMessages, assistantMessage]);

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        
        const chunk = decoder.decode(value);
        const lines = chunk.split('\n\n');
        
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            const data = JSON.parse(line.slice(6));
            
            if (data.done) {
              setIsTyping(false);
              break;
            } else if (data.content) {
              setIsTyping(true);
              assistantMessage.content += data.content;
              setChatMessages((prevMessages) => [
                ...prevMessages.slice(0, -1),
                { ...assistantMessage },
              ]);
            }
          }
        }
      }

      await saveMessageToHistory(assistantMessage.content, "assistant");
      
      // Generate related questions after receiving the complete response
      await generateRelatedQuestions(assistantMessage.content);
    } catch (error) {
      console.error("Error:", error);
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          role: "error",
          content: "An error occurred while processing your request.",
        },
      ]);
    } finally {
      setIsLoading(false);
      setIsTyping(false);
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Optionally, you can show a brief notification that the text was copied
        alert("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
      });
  };

  const SuggestedQuestions = ({ questions, onQuestionClick }) => (
    <div className="mt-4 space-y-2">
      {questions.map((question, index) => (
        <button
          key={index}
          onClick={() => onQuestionClick(question)}
          className="w-full text-left px-3 py-1 bg-gray-100 rounded-lg hover:bg-gray-200 transition-colors duration-200 text-sm underline text-blue-600"
          title={question} // Full question in tooltip
        >
          <span className="block">
            {question}
          </span>
        </button>
      ))}
    </div>
  );

  useEffect(() => {
    const fetchInitialSummary = async () => {
      if (isAuthenticated && chatMessages.length === 0) {
        try {
          const response = await fetchWithToken(`${API_BASE_URL}/api/chat`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              messages: [],
              documentId: selectedDocument,
            }),
          });

          const data = await response.json();
          if (data.content && Array.isArray(data.content) && data.content.length > 0) {
            setChatMessages([{ role: "assistant", content: data.content[0].text }]);
          }
        } catch (error) {
          console.error("Error fetching initial summary:", error);
        }
      }
    };

    if (!authLoading) {
      fetchInitialSummary();
    }
  }, [isAuthenticated, authLoading, chatMessages.length, fetchWithToken, selectedDocument]);

  return (
    <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm h-full flex flex-col">
      <div className="flex flex-col h-full">
        {/* Document selection dropdown */}
        <div className="mb-4">
          <select
            value={selectedDocument || ""}
            onChange={(e) => handleDocumentChange(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select a document</option>
            {documents.map((doc) => (
              <option key={doc.id} value={doc.id}>
                {doc.filename}
              </option>
            ))}
          </select>
          {/* CHANGE: Added flex, justify-between, and items-center to this div */}
          <div className="mt-2 text-sm text-gray-600 flex justify-between items-center">
            {/* CHANGE: Wrapped Note and icon in a flex container */}
            <span className="ml-2 flex items-center">
              Note:
              <span className="inline-block relative group ml-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-gray-400 inline-block"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="invisible group-hover:visible opacity-0 group-hover:opacity-100 transition-opacity bg-gray-800 text-white text-xs rounded py-1 px-2 absolute left-0 bottom-full mb-1 w-64 text-center z-10">
                  While selected the document will be considered in responses.
                </span>
              </span>
            </span>
            {/* CHANGE: Moved buttons here, between Note and empty div */}
            <div className="flex space-x-2">
              <button className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={handleResourcesClick}
              >
                Resources
              </button>
              <button
                className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600"
                onClick={handleGetStartedClick}
              >
                Get Started
              </button>
              <button 
              ref={quickTipButtonRef}
          className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600"
          onClick={showRandomTip}
        >
          Quick Tips
        </button>
            </div>
            {/* Add the tip popup */}
      {showTip && (
        <div 
          className="absolute z-10 p-4 bg-white border-2 border-gray-700 rounded-lg shadow-lg"
          style={{
            top: `${popupPosition.top}px`,
            left: `${popupPosition.left}px`,
            maxWidth: '150px', 
          }}
          onMouseLeave={() => setShowTip(false)}
        >
                <p>{currentTip}</p>
                {/* "Tell me more" Button */}
                <button
                  onClick={handleTellMeMore}
                  className="mt-4 w-full px-3 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors duration-200"
                >
                  Tell me more
                </button>
              </div>
            )}
            <div className="w-20"></div>{" "}
            {/* This empty div balances the layout */}
          </div>
        </div>

        {/* Chat area */}
        <div className="flex-1 overflow-y-auto p-6 space-y-6">
          {chatMessages
            .filter((message) => !message.hidden)
            .map((message, index) => (
              <div
                key={index}
                className={`p-6 rounded-lg shadow-md relative ${
                  message.role === "user"
                    ? "bg-blue-100 ml-12"
                    : message.role === "assistant"
                      ? "bg-white mr-12"
                      : message.role === "system"
                        ? "bg-yellow-100 text-center"
                        : "bg-red-100"
                }`}
              >
                <ReactMarkdown
                  className="prose max-w-none"
                  components={{
                    h1: ({ node, ...props }) => (
                      <h1 className="text-2xl font-bold mb-4" {...props} />
                    ),
                    h2: ({ node, ...props }) => (
                      <h2 className="text-xl font-semibold mb-3" {...props} />
                    ),
                    p: ({ node, ...props }) => (
                      <p className="mb-4" {...props} />
                    ),
                    ul: ({ node, ...props }) => (
                      <ul className="list-disc pl-6 mb-4" {...props} />
                    ),
                    ol: ({ node, ...props }) => (
                      <ol className="list-decimal pl-6 mb-4" {...props} />
                    ),
                    li: ({ node, ...props }) => (
                      <li className="mb-2" {...props} />
                    ),
                  }}
                >
                  {message.content}
                </ReactMarkdown>
                {message.role === "assistant" && (
                  <button
                    onClick={() => copyToClipboard(message.content)}
                    className="absolute bottom-2 left-2 text-gray-500 hover:text-gray-700"
                    title="Copy to clipboard"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path d="M8 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z" />
                      <path d="M6 3a2 2 0 00-2 2v11a2 2 0 002 2h8a2 2 0 002-2V5a2 2 0 00-2-2 3 3 0 01-3 3H9a3 3 0 01-3-3z" />
                    </svg>
                  </button>
                )}
              </div>
            ))}
          {isLoading && (
            <div className="text-center text-gray-500">Loading...</div>
          )}
          {isTyping && (
            <div className="typing-indicator">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
        </div>
        {/* New code: Suggested Questions */}
        {/* Only render if there are suggested questions */}
        {suggestedQuestions.length > 0 && (
      <div className="mt-4 p-3 bg-gray-50 rounded-lg border border-gray-200">
        <SuggestedQuestions
          questions={suggestedQuestions}
          onQuestionClick={handleSuggestedQuestionClick}
        />
      </div>
    )}
        {/* Input form */}
        <form
  ref={formRef}
  onSubmit={handleSubmit}
  className="p-6 bg-white border-t border-gray-200"
>
  <div className="flex space-x-4">
    <input
      ref={inputRef}
      type="text"
      value={input}
      onChange={(e) => setInput(e.target.value)}
      className="flex-1 p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      placeholder="Type your question here..."
      disabled={isLoading}
    />
    <button
      type="submit"
      className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
      disabled={isLoading}
    >
      Send
    </button>
    <button
      type="button"
      onClick={clearChat}
      className="px-6 py-3 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
    >
      Clear Chat
    </button>
  </div>
</form>
      </div>
    </div>
  );
};
export default ChatInterface;