import React from 'react';
import { Link } from 'react-router-dom';

const GetStarted = () => {
    return (
        <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md border border-black">
          <h1 className="text-3xl font-bold mb-6">How I Can Help You</h1>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Welcome to Your Educational Support Assistant
            </h2>
            <p className="mb-4">
              I'm here to provide informed, empathetic, and practical guidance to
              educators, families, and support staff in the realm of special
              education. My goal is to help you navigate the complexities of
              Individualized Education Programs (IEPs), transition planning, and
              inclusive education practices.
            </p>
            <p className="mb-4">
              Whether you're an experienced educator, a concerned parent, or a
              support staff member, I'm here to offer support and information
              tailored to your specific needs and questions.
            </p>
          </section>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              Navigating the App
            </h2>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                <strong>Chat:</strong> Your main interface for interacting with me,
                your AI assistant. Ask your questions here! 
              </li>
              <li>
                <strong>History:</strong> Review and export your past conversations for future reference. 
              </li>
              <li>
                <strong>Documents:</strong> Upload and manage important files. You can also ask me to analyze them to provide context for your questions. 
              </li>
              <li>
                <strong>Profile:</strong> Customize your user information to enhance
                our AI interactions and help me understand your specific needs.
              </li>
            </ul>
          </section>
    
          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">
              What I Can Assist You With
            </h2>
            <p className="mb-4">
              I can provide information, guidance, and resources related to:
            </p>
            <ul className="list-disc pl-6 space-y-4">
              <li>
                <strong>Special Education Principles and Practices:</strong>{' '}
                Understanding core concepts, current best practices, and strategies
                for creating inclusive learning environments.
              </li>
              <li>
                <strong>IEP Development and Implementation:</strong> Navigating the
                process of creating, reviewing, and implementing effective IEPs that
                truly serve the student's needs.
              </li>
              <li>
                <strong>Transition Planning:</strong> Developing comprehensive
                transition plans for students moving to post-secondary education or
                employment.
              </li>
              <li>
                <strong>Collaborative Approaches:</strong> Fostering effective
                collaboration among educators, families, and support staff.
              </li>
              <li>
                <strong>Evidence-Based Teaching Strategies:</strong> Exploring
                research-backed methods for supporting diverse learners.
              </li>
              <li>
                <strong>Progress Monitoring and Goal Setting:</strong>{' '}
                Understanding effective ways to track student progress and set
                achievable goals.
              </li>
              <li>
                <strong>Educational Law and Policy (General Information):</strong>{' '}
                Gaining a general understanding of special education laws and
                policies (always consult legal professionals for specific advice).
              </li>
            </ul>
          </section>
    
          <section className="mb-8">
  <h2 className="text-2xl font-semibold mb-4">
    How to Get the Most Out of Our Conversation
  </h2>
  <p className="mb-4">
    I'm here to provide personalized guidance. To help me understand your needs and provide the most relevant information, try these tips:
  </p>
  <ul className="list-disc pl-6 space-y-2">
    <li>
      **Be Clear and Specific:**  Just like talking to a person, tell me exactly what you need help with. The more details you provide, the better I can assist you.
    </li>
    <li>
      **Paint a Picture with Context:**  Give me background information about the student or situation you're dealing with. For example, tell me the student's grade level, any specific challenges they face, or what you've already tried.
    </li>
    <li>
      **Share Your Resources:** Let me know what resources or support systems are currently available to you. This helps me tailor my suggestions to your specific context. If you find yourself telling me things multiple times add the information to your Profile.
    </li>
    <li>
      **Don't Be Afraid to Ask!** I learn from every interaction. If you need more explanation, have follow-up questions, or want to explore a topic further, just ask! 
    </li>
  </ul>
</section>
    
          <p className="mb-6">
            I'm here to support you in creating effective, inclusive, and
            personalized educational experiences for students with diverse needs.
            Every question is valuable, and I'm here to help you explore solutions
            and strategies. Shall we begin our conversation?
          </p>
    
          <Link
            to="/"
            className="inline-block mt-4 px-6 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition duration-200"
          >
            Start a Conversation
          </Link>
        </div>
      );
    };

export default GetStarted;