import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const Welcome = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-8">
      <h1 className="text-4xl font-bold mb-6 text-center">Welcome to Asterio</h1>
      <p className="text-xl mb-8 text-center max-w-2xl">
        Your trusted companion in navigating the complexities of special education and secondary transition.
      </p>
      
      <div className="bg-white rounded-lg shadow-lg p-8 mb-8 max-w-3xl">
        <h2 className="text-2xl font-semibold mb-4">Our Mission</h2>
        <p className="mb-6">
          At Asterio, we believe that every student deserves a tailored approach to education that addresses their unique strengths, challenges, and aspirations. Our mission is to empower educators, students, and families to work together to create inclusive, effective, and supportive learning environments.
        </p>
        
        <h2 className="text-2xl font-semibold mb-4">What Asterio Can Do for You</h2>
        <ul className="list-disc list-inside mb-6">
          <li>Develop personalized IEPs</li>
          <li>Get guidance and support</li>
          <li>Collaborate with stakeholders</li>
          <li>Track progress and set goals</li>
          <li>Access evidence-based strategies</li>
        </ul>
        
        <h2 className="text-2xl font-semibold mb-4">Benefits of Using Asterio</h2>
        <ul className="list-disc list-inside">
          <li>Improve student outcomes</li>
          <li>Enhance collaboration</li>
          <li>Reduce stress and overwhelm</li>
          <li>Increase efficiency</li>
        </ul>
      </div>
      
      <p className="text-xl mb-8 text-center">Please log in to access the AI chat interface and start your journey with Asterio.</p>
      <button
        onClick={() => loginWithRedirect()}
        className="px-6 py-3 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
      >
        Log In
      </button>
    </div>
  );
};

export default Welcome;