import React from 'react';
import { Link } from 'react-router-dom';

const Resources = () => {
  const resources = {
    iep: [
      { 
        name: 'Wrightslaw', 
        url: 'https://www.wrightslaw.com/',
        description: 'Comprehensive resource for special education law, advocacy, and IEP information.' 
      },
      { 
        name: 'Understood.org', 
        url: 'https://www.understood.org/',
        description: 'User-friendly information and resources for parents and educators of children with learning and attention issues.' 
      },
      { 
        name: 'Center for Parent Information and Resources (CPIR)', 
        url: 'https://www.parentcenterhub.org/',
        description: 'Provides information and resources to help parents of children with disabilities participate more effectively in their education.' 
      },
      {
        name: 'National Center for Learning Disabilities (NCLD)',
        url: 'https://www.ncld.org/',
        description: 'Offers support and resources for individuals with learning disabilities and their families.'
      },
      // ... add more IEP resources
    ],
    transition: [
      {
        name: 'National Secondary Transition Technical Assistance Center (NSTTAC)',
        url: 'https://www.nsttac.org/',
        description: 'Provides national leadership and support to assist states in improving their capacity to produce positive post-school outcomes.'
      },
      {
        name: 'PACER Center: Transition Resources',
        url: 'https://www.pacer.org/transition/',
        description: 'Offers a wealth of information, tools, and resources for families and educators navigating the transition process.'
      },
      {
        name: 'Think College',
        url: 'https://thinkcollege.net/',
        description: 'Focuses on increasing inclusive higher education options for students with intellectual disability.'
      },
      // ... add more transition resources
    ],
    assistiveTechnology: [
      {
        name: 'LD Technology',
        url: 'https://www.ldtechnology.org/', 
        description: 'Provides resources and information on assistive technology for individuals with learning disabilities.'
      },
      {
        name: 'Tech Matrix',
        url: 'https://techmatrix.org/',
        description: 'Helps educators, parents, and others find technology that can support the learning of students with disabilities.'
      },
      {
        name: 'Closing The Gap',
        url: 'https://www.closingthegap.com/',
        description: 'Provides information and resources on assistive technology, access, and accommodations for individuals with disabilities.'
      },
      // ... add more assistive technology resources 
    ],
    advocacy: [
      { 
        name: 'Council of Parent Attorneys and Advocates (COPAA)', 
        url: 'https://www.copaa.org/',
        description: 'National education advocacy organization for students with disabilities.'
      },
      {
        name: 'Disability Rights Education & Defense Fund (DREDF)',
        url: 'https://dredf.org/', 
        description: 'Works to advance the civil and human rights of people with disabilities through legal advocacy, training, education, and public policy and legislative development.'
      },
      // ... add more advocacy resources
    ],
    governmentAndPolicy: [
      { 
        name: 'U.S. Department of Education: Office of Special Education Programs (OSEP)', 
        url: 'https://sites.ed.gov/idea/',
        description: 'Official website for IDEA (Individuals with Disabilities Education Act) information and resources.' 
      },
      {
        name: 'National Dissemination Center for Children with Disabilities (NICHCY)',
        url: 'https://nichcy.org/',
        description: 'Provides information on disabilities and disability-related issues, focusing on children and youth.'
      },
      // ... add more government and policy resources
    ],
    // ... add more categories as needed
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 p-6 bg-white rounded-lg shadow-md border border-black">
      <h1 className="text-3xl font-bold mb-6">Valuable Resources</h1>

      {Object.entries(resources).map(([category, links]) => (
        <section key={category} className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            {category.replace(/([A-Z])/g, ' $1').toUpperCase()} Resources
          </h2>
          <ul className="list-disc pl-6 space-y-2">
            {links.map((resource) => (
              <li key={resource.url}>
                <a
                  href={resource.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500 hover:underline"
                >
                  {resource.name}
                </a>
                {resource.description && (
                  <p className="text-gray-600 mt-1 ml-4">{resource.description}</p>
                )}
              </li>
            ))}
          </ul>
        </section>
      ))}
    </div>
  );
};

export default Resources;